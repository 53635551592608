import React, { useEffect } from 'react'
import { getDatasources } from 'ducks/apps/datasources'
import { withRouter } from 'react-router-dom'
import { connect, useDispatch, useSelector } from 'react-redux'
import { getParams } from 'ducks/apps/params'
import { getApp, updateApp } from 'ducks/apps'
import SettingsFormField from 'components/Shared/Forms/SettingsFormField'
import { Field, formValueSelector, initialize, reduxForm } from 'redux-form'
import Button from 'components/Shared/Button'
import { getAppHost } from 'ducks/domains/utils'
import { getDomains } from 'ducks/domains'
import { toast } from 'react-toastify'

import './ScreenDeepLinking.scss'

const FORM_NAME = 'screenDeepLinkingForm'

const validate = values => {
  const errors = {}

  const cleanedAlias = values.alias?.startsWith('/')
    ? values.alias.slice(1)
    : values.alias || ''

  const regex = /^[a-zA-Z0-9-_]+$/

  if (!regex.test(cleanedAlias) && cleanedAlias.length > 0) {
    errors.alias = 'Invalid screen route name'
  } else if (cleanedAlias.length > 50) {
    errors.alias = 'Screen route name must be less than 50 characters'
  }

  return errors
}

const ScreenDeepLinking = ({
  component,
  app,
  handleSubmit,
  submitting,
  alias,
}) => {
  const domains = useSelector(state => getDomains(state, app.OrganizationId))
  const host = getAppHost({ app, domains, useAppDomain: true })
  const appUrl = `${host}${app.path || ''}`

  const dispatch = useDispatch()
  useEffect(() => {
    if (!app?.deepLinking) return

    const aliases = app.deepLinking.aliases || {}
    const aliasKey =
      Object.keys(aliases).find(
        key => aliases[key].componentId === component.id
      ) || ''

    const initialValues = { alias: aliasKey }
    dispatch(initialize(FORM_NAME, initialValues))
  }, [app, component.id, initialize])

  const onSubmit = async values => {
    if (!app) return
    await new Promise(resolve => setTimeout(resolve, 200))

    const { alias } = values
    const cleanedAlias = alias ? alias.replace(/^\/+/, '') : ''

    const { deepLinking = {} } = app
    const { aliases = {} } = deepLinking

    // Remove existing entries for this component
    const newAliases = Object.entries(aliases).reduce((acc, [key, value]) => {
      if (value.componentId !== component.id) {
        acc[key] = value
      }

      return acc
    }, {})

    // Add new alias if not empty
    if (cleanedAlias) {
      newAliases[cleanedAlias] = { componentId: component.id }
    }

    await dispatch(
      updateApp(app.id, {
        deepLinking: {
          ...deepLinking,
          aliases: newAliases,
        },
      })
    )
  }

  const onCopyUrl = e => {
    e.preventDefault()

    const { deepLinking = {} } = app
    const { aliases = {} } = deepLinking

    const savedAliasKey =
      Object.keys(aliases).find(
        key => aliases[key]?.componentId === component.id
      ) || ''

    const finalAlias = savedAliasKey.replace(/^\/+/, '')

    const sanitizedAppUrl = appUrl.replace(/\/+$/, '')

    const urlToBeCopied = finalAlias
      ? `${sanitizedAppUrl}/${finalAlias}`
      : sanitizedAppUrl

    navigator.clipboard
      .writeText(urlToBeCopied)
      .then(() => {
        toast.success('URL copied to clipboard!', {
          position: toast.POSITION.BOTTOM_LEFT,
        })
      })
      .catch(err => {
        console.error('Could not copy URL:', err)
      })
  }

  const checkIfCanSave = () => {
    const { aliases = {} } = app.deepLinking || {}
    const oldAliasKey =
      Object.keys(aliases).find(
        key => aliases[key]?.componentId === component.id
      ) || ''

    const newAlias = (alias || '').replace(/^\/+/, '')
    const oldAlias = (oldAliasKey || '').replace(/^\/+/, '')

    return newAlias !== oldAlias
  }

  return (
    <form className="params-section">
      <div className="screen-deep-linking">
        <Field
          component={SettingsFormField}
          name="alias"
          label="Screen Route Name"
          placeholder="Enter a screen route name"
          type="text"
          parse={value => (value?.startsWith('/') ? value : `/${value}`)}
          format={value => (value?.startsWith('/') ? value : `/${value}`)}
          tooltip="This is the name of the route that will be used to navigate to this screen, e.g. /profile, /settings"
        />
        <div className="screen-route-btns-row">
          <div className="screen-route-copy-url">
            <Button small outlined teal disabled={false} onClick={onCopyUrl}>
              {'Copy URL'}
            </Button>
          </div>
          <div className="screen-route-save-button">
            <Button
              small
              disabled={submitting || !checkIfCanSave()}
              onClick={handleSubmit(onSubmit)}
            >
              {submitting ? 'Saving...' : 'Save Route'}
            </Button>
          </div>
        </div>
      </div>
    </form>
  )
}

const selector = formValueSelector(FORM_NAME)

const mapStateToProps = (state, { match, component }) => ({
  datasources: getDatasources(state, match.params.appId),
  params: getParams(state, match.params.appId, component.id),
  app: getApp(state, match.params.appId),
  alias: selector(state, 'alias'),
})

export default withRouter(
  connect(mapStateToProps)(
    reduxForm({
      form: FORM_NAME,
      enableReinitialize: false,
      touchOnChange: true,
      touchOnBlur: true,
      initialValues: {
        alias: '',
      },
      validate,
      onSubmit: (values, dispatch, props) => {
        const { onSubmit } = props
        if (onSubmit) {
          onSubmit(values)
        }
      },
    })(ScreenDeepLinking)
  )
)
